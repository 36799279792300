<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
             <button class="btn-search ml-5" type="button" @click="setTableData()">
               <i class="fas fa-search"></i>
             </button>
           </div>
           <!-- <a class="btn-table" @click="onChangeType('table')">{{ $t('button.tableType') }}</a>
           <a class="btn-table" @click="onChangeType('graph')">{{ $t('button.graphType') }}</a> -->
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" @click="onChangeType('table')" class="tab-item" :class="{'active': type === 'table'}">{{ $t('button.tableType') }}</button>
        <button type="button" @click="onChangeType('graph')" class="tab-item" :class="{'active': type === 'graph'}">{{ $t('button.graphType') }}</button>
      </div>
    </div>
    <div class="main-contents-wrapper" v-if="type == 'table'">
      <div class="table-wrapper">
         <table class="mainTable">
            <thead class="table-light">
               <tr>
                  <th>{{ $t('table.head.type') }}</th>
                  <th>{{ $t('table.head.gamecompany') }}</th>
                  <th>{{ $t('table.head.betAmt') }}</th>
                  <th>{{ $t('table.head.betwlt') }}</th>
                  <th>{{ $t('searchArea.insApplyYN') }}</th>
                  <th>{{ $t('table.head.insuranceSet') }}</th>
                  <th>{{ $t('searchArea.amountCreditInsur') }}</th>
                  <!--th>{{ $t('table.head.amountCredit') }}</th-->
                  <th>{{ $t('table.head.Realcredit') }}</th>
                  <th>{{ $t('table.head.BITotalAmt') }}</th>
                  <th>{{ $t('table.head.creditSubpointAmt') }}</th>
               </tr>
            </thead>
            <tbody>
               <template v-for="(category, value) in reportData">
                 <template v-for="(item, idx) in category" :key="item.product">
                   <tr>
                     <td v-if="idx === 0" :rowspan="category.length" class="bg">{{ getCategoryNameText(item.categoryName) }}</td>
                     <td><span>{{item.productName}}</span></td>
                     <td class="roboto">{{ $t('table.head.betAmt') }}</td>
                     <td class="roboto">{{ numberWithCommas(item.totalWinLose) }}</td>
                     <td>{{ item.insureUseYn }}</td>
                     <td class="roboto">{{ item.insureRate}}%</td>
                     <td class="roboto">{{ numberWithCommas(item.insuredAmt) }}</td>
                     <!--td class="roboto">{{ numberWithCommas(item.totalCreditAmt)}}</td-->
                     <td class="roboto">{{ numberWithCommas(item.creditAmt) }}</td>
                     <td class="roboto">{{ numberWithCommas(item.insurencePoint) }}</td>
                     <td class="roboto">{{ numberWithCommas(item.creditPointAmt) }}</td>
                   </tr>
                 </template>
                 <tr class="fb" v-if="totalCategory[value]">
                   <td class="pointCol">{{ $t('table.head.summary') }}</td>
                   <td></td>
                   <td class="roboto">{{ $t('table.head.betAmt') }}</td>
                   <td class="roboto">{{ numberWithCommas(totalCategory[value].totalWinLose) }}</td>
                   <td>-</td>
                   <td>-</td>
                   <td class="roboto">{{ numberWithCommas(totalCategory[value].insuredAmt) }}</td>
                   <!--td class="roboto">{{ numberWithCommas(totalCategory[value].totalCreditAmt) }}</td-->
                   <td class="roboto">{{ numberWithCommas(totalCategory[value].creditAmt) }}</td>
                   <td class="roboto">{{ numberWithCommas(totalCategory[value].insurencePoint) }}</td>
                   <td class="roboto">{{ numberWithCommas(totalCategory[value].creditPointAmt) }}</td>
                 </tr>
               </template>
               <tr>
                  <td colspan="10" class="bg"></td>
               </tr>
            </tbody>
            <tfoot>
               <tr class="fb" v-if="total">
                 <td class="pointCol">{{ $t('table.head.summary') }}</td>
                 <td> </td>
                 <td class="roboto">{{ $t('table.head.betAmt') }}</td>
                 <td class="roboto">{{ numberWithCommas(total.totalWinLose) }}</td>
                 <td>-</td>
                 <td>-</td>
                 <td class="roboto">{{ numberWithCommas(total.insuredAmt) }}</td>
                 <!--td class="roboto">{{ numberWithCommas(total.totalCreditAmt) }}</td-->
                 <td class="roboto">{{ numberWithCommas(total.creditAmt) }}</td>
                 <td class="roboto">{{ numberWithCommas(total.insurencePoint) }}</td>
                 <td class="roboto">{{ numberWithCommas(total.creditPointAmt) }}</td>
               </tr>
            </tfoot>
         </table>
      </div>
    </div>
    <div class="main-contents-wrapper" v-if="type == 'graph'">
      <div class="main-chart-wrapper">
        <h2 class="tableName">{{ $t('txt.tendys') }}<span>{{ $t('txt.notdays') }}</span></h2>
        <div class="chart chartwidth">
          <template v-for="(chart) in lineChartList" :key="chart">
            <div class="chartWrap">
              <div>{{ getCategoryNameText(chart.categoryName) }}</div>
              <apexchart width="400" height="350" type="line" :options="chart.chartOptions" :series="chart.series"></apexchart>
            </div>
          </template>
        </div>
      </div>
      <div class="main-chart-wrapper">
        <h2 class="tableName">{{ $t('searchArea.sportCredit') }}</h2>
        <div class="dp-flex f-gap50 f-ac">
          <!-- <table class="mainTable top w320">
            <colgroup>
                <col width="40%">
                <col width="60%">
            </colgroup>
            <template v-for="(category, index) in chartCategoryLabel" :key="category">
              <tr v-if="chartCategoryValue[index] !== 0">
                <th>{{category}}</th>
                <td>{{ numberWithCommas(chartCategoryValue[index]) }}</td>
              </tr>
            </template>
          </table> -->
          <div class="chart w80w">
            <div class="chartWrapL">
              <apexchart height="400" type="pie" :options="pieChartOptions" :series="pieSeries"></apexchart>
            </div>
            <div class="chartWrapL">
              <apexchart height="400" type="bar" :options="barChartOptions" :series="barSeries"></apexchart>
            </div>
            <div class="chartWrapL"> <!--columns 100 chart-->
              <apexchart height="400" type="bar" :options="colChartOptions" :series="colSeries"></apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="main-chart-wrapper">
        <h2 class="tableName">{{ $t('company.companyCredit') }}</h2>
        <div class="dp-flex f-gap50 f-ac">
          <!-- <table class="mainTable top w320">
            <colgroup>
                <col width="40%">
                <col width="60%">
            </colgroup>
            <template v-for="(game, index) in chartGameLabel" :key="game">
              <tr v-if="chartGameValue[index] !== 0">
                <th>{{game}}</th>
                <td>{{ numberWithCommas(chartGameValue[index]) }}</td>
              </tr>
            </template>

          </table> -->
          <div class="chart w80w">
            <div class="chartWrapL">
              <apexchart height="400" :options="pieChartOptionsGame" :series="pieSeriesGame"></apexchart>
            </div>
            <div class="chartWrapL">
              <apexchart height="400" type="bar" :options="barChartOptionsGame" :series="barSeriesGame"></apexchart>
            </div>
            <div class="chartWrapL"> <!--columns 100 chart-->
              <apexchart height="400" type="bar" :options="colChartOptionsGame" :series="colSeriesGame"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DateSelector from '@/components/common/DateSelector'
import { creatReport } from '@/api/insured'
import { numberWithCommas, thousand } from '@/libs/utils'
// import { thousand } from '@/libs/utils.js'
import Memo from '@/components/common/memo'

export default {
  name: 'useReport',
  components: {
    DateSelector,
    Memo
  },
  data () {
    return {
      reportData: {},
      totalCategory: {},
      total: {},
      totalCount: 0,
      reqData: {
        startDate: '',
        endDate: ''
      },
      startDefault: '',
      endDefault: '',
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      chartCategoryLabel: [],
      chartCategoryValue: [],
      chartGameLabel: [],
      chartGameValue: [],
      lineChartColor: ['#59A9D5', '#5272F2', '#005B41', '#FF8080', '#FF6969', '#9EDDFF', '#79AC78', '#FACBEA', '#B3A492'],
      lineChartList: {},

      type: 'table',
      series: [{
        data: []
      }],
      barSeries: [{
        name: '소비알량',
        data: []
      }],
      pieSeries: [],
      colSeries: [{
        data: []
      }],
      colSeriesGame: [{
        data: []
      }],
      barSeriesGame: [{
        name: '소비알량',
        data: []
      }],
      barSeriesVen: [{
        data: []
      }],
      pieSeriesVen: [],
      chartOptions: {
        series: [{
          name: '소비알량',
          data: []
        }],
        chart: {
          height: 350,
          zoom: {
            enabled: false
          }
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 1
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return thousand(value)
            }
          }
        }
      },
      barChartOptions: {
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            distributed: true,
            columnWidth: '40px'
          }
        },
        xaxis: {
          categories: [],
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return thousand(value)
          }
        },
        dataLabels: {
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return thousand(value)
          },
          style: {
            colors: ['#353535']
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderWidth: 1,
            borderColor: '#353535'
          }
        },
        legend: {
          show: false
        },
        colors: ['#26A0FC', '#26E7A6', '#FEBC3B', '#FF6178', '#8B75D7', '#26DFEC'],
        labels: [],
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return thousand(value)
            }
          }
        }
      },
      barChartOptionsGame: {
        dataLabels: {
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return thousand(value)
          },
          style: {
            colors: ['#353535']
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderWidth: 1,
            borderColor: '#353535'
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '40px',
            borderRadius: 4,
            horizontal: false,
            distributed: true
          }
        },
        legend: {
          show: false
        },
        colors: ['#26A0FC', '#26E7A6', '#FEBC3B', '#FF6178', '#8B75D7', '#26DFEC'],
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return thousand(value)
            }
          }
        }
      },
      colChartOptions: {
        chart: {
          type: 'bar',
          height: 400,
          stacked: true,
          stackType: '100%'
        },
        xaxis: {
          categories: []
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return thousand(value)
            }
          }
        }
      },
      colChartOptionsGame: {
        chart: {
          type: 'bar',
          height: 400,
          stacked: true,
          stackType: '100%'
        },
        xaxis: {
          categories: []
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return thousand(value)
            }
          }
        }
      },
      pieChartOptions: {
        dataLabels: {
          style: {
            colors: ['#353535']
          },
          dropShadow: {
            opacity: 0
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderWidth: 1,
            borderColor: '#353535'
          }
        },
        legend: {
          position: 'bottom'
        },
        colors: ['#26A0FC', '#26E7A6', '#FEBC3B', '#FF6178', '#8B75D7', '#26DFEC'],
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return thousand(value)
            }
          }
        }
      },
      pieChartOptionsGame: {
        chart: {
          type: 'pie'
        },
        dataLabels: {
          style: {
            colors: ['#353535']
          },
          dropShadow: {
            opacity: 0
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderWidth: 1,
            borderColor: '#353535'
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: true
          }
        },
        legend: {
          position: 'bottom'
        },
        colors: ['#26A0FC', '#26E7A6', '#FEBC3B', '#FF6178', '#8B75D7', '#26DFEC']
      },
      barChartOptionsVen: {
        chart: {
          type: 'bar',
          height: 350
        },
        dataLabels: {
          style: {
            colors: ['#353535']
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false
          }
        },
        colors: ['#26A0FC', '#26E7A6', '#FEBC3B', '#FF6178', '#8B75D7', '#26DFEC']
      },
      pieChartOptionsVen: {
        dataLabels: {
          style: {
            colors: ['#353535']
          },
          dropShadow: {
            opacity: 0
          }
        },
        colors: ['#26A0FC', '#26E7A6', '#FEBC3B', '#FF6178', '#8B75D7', '#26DFEC']
      }
    }
  },
  methods: {
    numberWithCommas,
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = this.getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.endDefault = _date
      this.reqData.endDate = this.getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    onChangeType (type) {
      this.type = type
    },
    setTableData () {
      this.emitter.emit('Loading', true)
      creatReport(this.reqData).then(res => {
        console.log('ddddd : ', res)
        if (res.resultCode === '0') {
          const list = res.data.creditReports
          const periodData = res.data.creditDailyReports
          this.normalizeData(list)
          this.normalizePeriodData(periodData)
        }
        this.emitter.emit('Loading', false)
      })
    },
    normalizePeriodData (list) {
      const totalCategory = {}

      list.forEach(item => {
        const category = item.categoryName

        if (!totalCategory[category]) {
          totalCategory[category] = {
            days: [],
            creditAmt: [],
            chartOptions: {
              ...this.chartOptions
            },
            series: [{
              name: '소비알량',
              data: []
            }]
          }
        }

        totalCategory[category].chartOptions.colors = []

        totalCategory[category].creditAmt.push(item.creditAmt)
        totalCategory[category].days.push(item.days)

        totalCategory[category].series[0].data = totalCategory[category].creditAmt
        totalCategory[category].chartOptions.xaxis.categories = totalCategory[category].days
      })

      totalCategory.total = {
        days: [],
        creditAmt: [],
        chartOptions: {
          ...this.chartOptions
        },
        series: [{
          name: '소비알량',
          data: []
        }]
      }

      let i = 0
      const totalAmt = []
      this.colSeries = []
      for (const category in totalCategory) {
        if (i === 0) {
          totalCategory.total.chartOptions.colors = []
          totalCategory.total.days = totalCategory[category].days
          totalCategory.total.chartOptions.xaxis.categories = totalCategory[category].days
        }
        totalCategory[category].chartOptions.colors.push(this.lineChartColor[i])
        totalCategory[category].categoryName = category

        const creditList = totalCategory[category].creditAmt
        for (let i = 0, iLen = creditList.length; i < iLen; i++) {
          if (!totalAmt[i]) {
            totalAmt[i] = 0
          }
          totalAmt[i] += creditList[i]
        }
        i++

        this.colSeries.push({
          name: category,
          data: totalCategory[category].creditAmt
        })
      }

      this.colChartOptions.xaxis.categories = totalCategory.total.days

      totalCategory.total.creditAmt = totalAmt
      totalCategory.total.series[0].data = totalAmt

      console.log('total 10일간 알 사용량: ', totalAmt)
      console.log(totalCategory)

      this.lineChartList = totalCategory

      console.log('daily : ', totalCategory)
    },
    normalizeData (list) {
      const reportData = {}
      const totalCategory = {}
      const totalGame = {}

      const total = {
        creditAmt: 0,
        creditPointAmt: 0,
        insureRate: 0,
        totalCreditAmt: 0,
        totalWinLose: 0,
        insuredAmt: 0,
        insurencePoint: 0
      }
      list.forEach(item => {
        const category = item.categoryName
        const gameName = item.productName

        // if (Number(item.creditAmt) !== 0) {
        //   if (!reportData[category]) {
        //     reportData[category] = []
        //   }
        //   reportData[category].push(item)
        // }

        if (!reportData[category]) {
          reportData[category] = []
        }
        reportData[category].push(item)

        if (!totalCategory[category]) {
          totalCategory[category] = {
            creditAmt: 0,
            creditPointAmt: 0,
            insureRate: 0,
            totalCreditAmt: 0,
            totalWinLose: 0,
            insuredAmt: 0,
            insurencePoint: 0
          }
        }

        if (!totalGame[gameName]) {
          totalGame[gameName] = {
            creditAmt: 0,
            creditPointAmt: 0,
            insureRate: 0,
            totalCreditAmt: 0,
            totalWinLose: 0,
            insuredAmt: 0,
            insurencePoint: 0
          }
        }

        for (const field in totalCategory[category]) {
          totalCategory[category][field] += Number(item[field])
          total[field] += Number(item[field])
        }

        for (const field in totalGame[gameName]) {
          totalGame[gameName][field] += Number(item[field])
        }
      })

      this.chartCategoryLabel = []
      this.chartCategoryValue = []

      for (const category in totalCategory) {
        if (totalCategory[category].creditAmt !== 0) {
          this.chartCategoryLabel.push(category)
          this.chartCategoryValue.push(totalCategory[category].creditAmt)
        }
      }
      this.barChartOptions.labels = this.chartCategoryLabel
      this.pieChartOptions.labels = this.chartCategoryLabel

      this.barSeries[0].data = this.chartCategoryValue
      this.pieSeries = this.chartCategoryValue.map(item => {
        return item < 0 ? 0 : item
      })

      this.chartGameValue = []
      this.chartGameLabel = []
      for (const game in totalGame) {
        if (totalGame[game].creditAmt !== 0) {
          this.chartGameLabel.push(game)
          this.chartGameValue.push(totalGame[game].creditAmt)
        }
      }

      this.barChartOptionsGame.labels = this.chartGameLabel
      this.pieChartOptionsGame.labels = this.chartGameLabel
      this.barSeriesGame[0].data = this.chartGameValue
      this.pieSeriesGame = this.chartGameValue.map(item => {
        return item < 0 ? 0 : item
      })

      console.log('category chart : ', this.chartCategoryLabel, this.chartCategoryValue)

      this.totalCount = list.length

      this.reportData = reportData
      this.totalCategory = totalCategory
      this.total = total
    },
    getCategoryNameText (categoryName) {
      if (categoryName === 'HC-카지노') {
        return '호텔 카지노'
      }
      return categoryName
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()

    this.setStartDate()
    this.setEndDate()
    await this.setTableData()
  }
}
</script>

<style scoped>
  .main-chart-wrapper { padding-bottom: 30px;border-bottom: 2px dotted #d4d4d4; }
  .main-chart-wrapper:last-child {border-bottom: 0;}
  .tableName {margin: 30px 0 20px; font-size: 20px; }
  .tableName span {font-size: 15px; margin-left: 10px;}
  .btn-table {height: 26px;background: #353535;color: #fff;margin-left: 15px;display: flex;align-items: center;padding: 0 10px;cursor: pointer;font-size: 14px;}
  .mainTable {table-layout: fixed;}
  .mainTable th {word-break: keep-all;}
  .mainTable.w80w {width:80% !important;}
  .mainTable.w40w {width:40% !important;}
  .mainTable.w320 {width:320px !important;}
  /* .chart.w80w {width: 80% !important;} */
  .chart.w60w {width:60% !important;}
  .mainTable.top {border-top: solid 5px #353535;}
  .mainTable .bg {box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);background-color: #f5f5f5;}
  .mainTable .fb td {background-color: #efefef;font-weight: 800;}
  .mainTable td {
    /*position: relative;*/
  }
  .pointCol {}
  .chart {display: flex; gap: 30px;flex-wrap: wrap; width: 100%;}
  /* .chartwidth {width: calc(100vw - 200px);} */
  .chartWrap { box-sizing: border-box; padding: 10px; border: 1px solid #d1d1d1; background: #fff; box-shadow: 2px 2px 5px #92929273; }
  .chartWrapL { width: calc(33% - 20px); box-sizing: border-box; padding: 10px; border: 1px solid #d1d1d1; background: #fff; box-shadow: 2px 2px 5px #92929273;}
  @media (min-width: 2113px) {
    .chart {justify-content: space-between;  max-width: 1778px;}
  }
</style>
